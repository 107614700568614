import { useLocation } from "@reach/router"
import axios from "axios"
import DOMPurify from "dompurify"
import { Script } from "gatsby"
import { parse as queryparse } from "query-string"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import {
  getAdditionalInfo,
  sendEventHumioLog,
  setHubspotutkCookie,
} from "../../common/FormBuilder/Form-Function"
import {
  checkCommonDomain,
  checkInvalid,
  checkValidEmail,
  checkValidName,
  checkValidUrl,
} from "../../utility/validation"
import Buttons from "../button"
import "./Form-builder.scss"

const AwardsFormBuilder = ({
  multiFormData,
  setMultiFormData,
  page,
  setPage,
  emptyFields,
  setEmptyFields,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [paramXeroProd, setparamXeroProd] = useState()
  const locationnew = useLocation()
  const searchParams = queryparse(locationnew.search)
  useEffect(() => {
    if (
      searchParams?.src === "xero" ||
      searchParams?.src === "productOnboarding"
    )
      setparamXeroProd(true)
  }, [searchParams])

  useEffect(() => {
    let checkbox = document.getElementById("Emerging MSP of the year")
    checkbox.checked = true
    checkboxClick(
      "Emerging MSP of the year",
      "Highlight your MSP's remarkable growth journey"
    )
  }, [])
  const [validity, setValidity] = useState(
    props.noCaptchaValidation ? true : false
  )
  const [uploadedType, setUploadedType] = useState() //getting file type uploaded and setting in state to verify and show error

  const [data, setData] = useState(
    props.type === "subscribe"
      ? [
          {
            name: "email",
            type: "email",
            placeholder: "Email address",
            errorMessage: "Please enter a valid email",
          },
        ]
      : props?.data
  )

  useEffect(() => {
    multiFormData &&
      setData(
        props.type === "subscribe"
          ? [
              {
                name: "email",
                type: "email",
                placeholder: "Email address",
                errorMessage: "Please enter a valid email",
              },
            ]
          : props?.data
      )
  }, [props.data])

  const currentLocation = useLocation()

  function CheckIpdata(resdata, alt) {
    const data = resdata != null && resdata !== "" ? resdata : alt
    return data
  }

  const [IpData, setIpData] = useState({
    continent: "No Continent",
    continentcode: "No Continentcode",
    countrycode: "No Countrycode",
    country: "No Country",
    state: "No State",
    city: "No City",
    zip: "No Zip",
    countryphoneCode: 0,
  })
  const [dailCodeLength, setDailCodeLength] = useState(0)

  useEffect(() => {
    if (
      props.IpStackData ||
      data.filter(el => el.type === "phone").length > 0
    ) {
      axios.get(process.env.IP_STACK_API).then(function(response) {
        props.currentLocation &&
          props.currentLocation(response.data.country_code)
        setIpData({
          continent: CheckIpdata(
            response.data.continent_name,
            IpData.continent
          ),
          continentcode: CheckIpdata(
            response.data.continent_code,
            IpData.continentcode
          ),
          countrycode: CheckIpdata(
            response.data.country_code,
            IpData.countrycode
          ),
          country: CheckIpdata(response.data.country_name, IpData.country),
          state: CheckIpdata(response.data.region_name, IpData.state),
          city: CheckIpdata(response.data.city, IpData.city),
          zip: CheckIpdata(response.data.zip, IpData.zip),
          countryphoneCode: CheckIpdata(
            response.data.location.calling_code,
            IpData.countryphoneCode
          ),
        })
      })
    }
  }, [])

  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id)

  //     if (!isScriptExist) {
  //       var script = document.createElement("script")
  //       script.type = "text/javascript"
  //       script.src = url
  //       script.id = id
  //       script.onload = function() {
  //         if (callback) callback()
  //       }
  //       document.body.appendChild(script)
  //     }

  //     if (isScriptExist && callback) callback()
  //   }

  //   loadScriptByURL(
  //     "recaptcha-key",
  //     `https://www.google.com/recaptcha/api.js?render=${process.env.CAPTCHA_KEY}`,
  //     function() {
  //       // console.log("Script loaded!")
  //     }
  //   )
  // }, [])

  const IpStackData = [
    {
      name: "continent",
      value: IpData.continent,
    },
    {
      name: "continent_code",
      value: IpData.continentcode,
    },
    {
      name: "country_code",
      value: IpData.countrycode,
    },
    {
      name: "country",
      value: IpData.country,
    },
    {
      name: "state",
      value: IpData.state,
    },
    {
      name: "city",
      value: IpData.city,
    },
    {
      name: "zip",
      value: IpData.zip,
    },
    {
      name: "country_phone_code",
      value: IpData.countryphoneCode,
    },
  ]

  function handleChange(val, idx, itemType) {
    const updatedData = [...data]
    if (itemType === "phone") {
      setDailCodeLength(val.data.dialCode.length)
      updatedData[idx].value = val.value
      // updatedData[idx].error = //false can be used to change error to false on change
    } else if (itemType === "select") {
      let selectedOptions = val.map(v => v.value)
      updatedData[idx].value = selectedOptions.join(", ")
    } else if (itemType === "checkbox") {
    } else {
      updatedData[idx].value = val
      // updatedData[idx].error = //false can be used to change error to false on change
    }
    setData(updatedData)
  }

  function enableError(idx) {
    const updatedData = [...data]
    updatedData[idx].error = true
    setData(updatedData)
  }

  function resetError(idx) {
    const updatedData = [...data]
    updatedData[idx].error = false
    setData(updatedData)
  }

  function checkInput() {
    data.map((el, idx) => {
      if (el.type === "email") {
        if (
          el?.value?.length === 0 ||
          (props.disallowCommonDomains && checkCommonDomain(el.value)) ||
          !checkValidEmail(el.value) ||
          el.value === undefined
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.name === "firstname") {
        if (
          !checkValidName(el.value) ||
          el?.value?.length === 0 ||
          el.value === undefined
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el?.validateEmpty) {
        if (el?.value?.length === 0 || el.value === undefined) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "checkbox") {
        el.choices.map(ele => {
          let checkbox = document.getElementById(ele)
          if (checkbox.checked) {
            let x = document.getElementById(`txt_area_${ele}`)
            if (x?.value === "") {
              let y = document.getElementById(`txt_area_err_${ele}`)
              y.style.display = "block"
              enableError(idx)
              setTimeout(function() {
                y.style.display = "none"
                resetError(idx)
              }, 3000)
            }
          }
        })
      } else if (el.type === "phone") {
        if (
          (el.required ?? true) &&
          (el?.value?.slice(dailCodeLength).length === 0 ||
            checkInvalid(el?.value?.slice(dailCodeLength)) ||
            el?.value?.slice(dailCodeLength) === undefined)
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "select") {
        //select input type validation
        // if (!(el?.value?.length > 0)) {
        //   enableError(idx)
        //   setTimeout(function() {
        //     resetError(idx)
        //   }, 3000)
        // }
        return
      } else if (el.type === "file") {
        if (
          (uploadedType?.data?.type != el.acceptFiletype ||
            uploadedType?.data?.size > 10485760) &&
          uploadedType != undefined
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        } else if (el.required && uploadedType === undefined) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "url") {
        if (
          (el.required ?? true) &&
          (checkValidUrl(el.value) || el.value === undefined || el.value == "")
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else if (el.type === "textarea") {
        if (
          (el.required ?? true) &&
          (checkInvalid(el.value) || el.value === undefined || el.value == "")
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      } else {
        if (
          (el.required ?? true) &&
          (el?.value?.length === 0 ||
            checkInvalid(el.value) ||
            el.value === undefined)
        ) {
          enableError(idx)
          setTimeout(function() {
            resetError(idx)
          }, 3000)
        }
      }
    })
  }

  const isSubmitValid = () => {
    const validationArr = data.filter((el, idx) => {
      if (el.error === true) {
        return el // no error
      }
    })
    return validationArr.length === 0 // returns true if there is no error
  }

  const getCaptchaToken = async () => {
    return new Promise(resolve => {
      window.hcaptcha
        .execute({ async: true })
        .then(({ response: token, key }) => {
          return resolve(token)
        })
    })
  }

  const validateCaptchaApi = async param => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    let res = await axios
      .get(
        `${process.env.CAPTCHA_API}?token=${param}&captchaProvider=HCAPTCHA`,
        config
      )
      .catch(error => {
        sendEventHumioLog(error.response, `Captcha Api Error`)
        props.errorHandler && props.errorHandler(data, error.response)
      })
    return res
  }

  const formSubmit = async (
    items,
    endpoint,
    pageUri,
    pageName,
    errorHandler
  ) => {
    var url = endpoint
    var additionalInfo = getAdditionalInfo()

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    var data = {
      submittedAt: Date.now(),
      fields: [...items, ...additionalInfo],
      context: {
        hutk: setHubspotutkCookie("hubspotutk"),
        pageUri: pageUri,
        pageName: `${pageName}`,
      },
    }
    var final_data = JSON.stringify(data)
    sendEventHumioLog(final_data, `Before hitting submit from ${document.URL}`)

    const response = await axios
      .post(url, {
        submittedAt: Date.now(),
        fields: [...items, ...additionalInfo],
        config,
        context: {
          hutk: setHubspotutkCookie("hubspotutk"),
          pageUri: pageUri,
          pageName: `${pageName}`,
        },
      })
      .then(response => {
        if (response.request.status === 200) {
          setLoading(false)
          if (props.afterSubmit) {
            props.afterSubmit(response, items)
          }
          sendEventHumioLog(
            final_data,
            response.data.inlineMessage,
            response.request.status,
            response.request.responseUrl,
            `After hitting submit & got success from ${document.URL}`
          )
          if (props.path) {
            window.location.href = `/thank-you?src=${props.path}`
          }
          return response
        } else if (
          response.request.status === 400 ||
          response.request.status === 403 ||
          response.request.status === 404
        ) {
          setLoading(false)
          sendEventHumioLog(
            final_data,
            response.data.inlineMessage,
            response.request.status,
            response.request.responseUrl,
            `After hitting submit & got failed from ${document.URL}`
          )
          const res = JSON.parse(response.request.responseText)
          errorHandler(data, res)
        }
      })
  }

  const getFileUploadUrl = async () => {
    let url = null
    let file = document.getElementById("uploaded-file").files[0]
    let form = new FormData()
    form.append("fileUpload", file)
    if (
      file &&
      uploadedType?.data?.type === uploadedType?.input?.acceptFiletype &&
      uploadedType?.data?.size < 10485760
    ) {
      let response = await fetch(`${process.env.GRAPHCMS_API_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_GRAPHCMS_AUTH_TOKEN_SPOS}`,
        },
        body: form,
      }).catch(error => {
        props.errorHandler && props.errorHandler(data, error.response)
      })

      const resData = await response.json()

      url = resData.url
      return url
    }
    return "No File"
  }

  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault()
    checkInput()

    const FinalSubmit = async () => {
      let finalData = []
      data.map((el, idx) => {
        if (el.type !== "checkbox") {
          finalData = [
            ...finalData,
            { name: el.name, value: DOMPurify.sanitize(el.value) },
          ]
        } else {
          el.choices.map(ele => {
            let checkbox = document.getElementById(ele)
            if (checkbox.checked) {
              let x = document.getElementById(`txt_area_${ele}`)
              if (x?.value) {
                finalData = [
                  ...finalData,
                  {
                    name: ele.replaceAll(" ", "_").toLowerCase(),
                    value: DOMPurify.sanitize(x.value),
                  },
                ]
              }
            }
          })
        }
      })

      if (props.IpStackData) {
        finalData.push(...IpStackData)
      }

      if (props?.extradata) {
        finalData.push(props?.extradata)
      }

      if (props.type === "subscribe") {
        formSubmit(
          finalData,
          props.endpoint
            ? props.endpoint
            : process.env.HUBSPOT_SUBSCRIBE_API_ENDPOINT,
          props.url ? props.url : process.env.HUBSPOT_SUBSCRIBE_API_URL,
          props.formName ? props.formName : "Subscription - Superops",
          props.path ? props.path : "subscription"
        )
      } else {
        var fullName = finalData.filter(el => el.name === "firstname")[0]
        if (multiFormData) {
          const separateObject = obj => {
            const res = []
            const keys = Object.keys(obj)
            keys.forEach(key => {
              res.push({
                name: key,
                value: obj[key],
              })
            })
            return res
          }
          finalData.push(...separateObject(multiFormData))
          finalData = finalData.filter(
            (v, i, a) => a.findIndex(v2 => v2.name === v.name) === i
          )
        }
        let dataObj = {}
        finalData.map(el => (dataObj[el.name] = el.value))
        if (!dataObj.hasOwnProperty("lastname")) {
          if (fullName.value.includes(" ")) {
            var firstName = fullName.value.substr(
              0,
              fullName.value.indexOf(" ")
            )
            var lastName = fullName.value.substr(
              fullName.value.indexOf(" ") + 1
            )
            fullName.value = firstName
            finalData.push({ name: "lastname", value: lastName })
          } else {
            finalData.push({ name: "lastname", value: " " })
          }
        }

        if (props.sessionStorage) {
          finalData.map((el, idx) => sessionStorage.setItem(el.name, el.value))
        }
        if (props.fileUpload) {
          let fileUrl = await getFileUploadUrl()
          finalData = [
            ...finalData,
            { name: "upload_file_link", value: fileUrl },
          ]
          formSubmit(finalData, props.endpoint, props.url, props.formName)
        } else {
          formSubmit(finalData, props.endpoint, props.url, props.formName)
        }
      }
    }
    if (!props.noCaptchaValidation) {
      let token = await getCaptchaToken()
      let captchaValid = await validateCaptchaApi(token)
      if (captchaValid?.data?.status && isSubmitValid()) {
        setValidity(true)
        FinalSubmit()
      } else {
        setLoading(false)
      }
    } else {
      if (isSubmitValid()) {
        FinalSubmit()
      } else {
        setLoading(false)
      }
    }
  }

  const checkboxClick = (ele, placeholder, scroll) => {
    let parentContainer = document.getElementById("awards_textarea")

    let checkbox = document.getElementById(ele)

    if (checkbox.checked) {
      let xy = document.createElement("div")
      xy.id = `txt_area_wrap_${ele}`
      xy.style.position = "relative"
      xy.classList.add("textarea-wrapper")

      let x = document.createElement("TEXTAREA")
      x.id = `txt_area_${ele}`
      x.placeholder = placeholder

      let y = document.createElement("div")
      y.classList.add("error-message", "txt-area-err")
      y.id = `txt_area_err_${ele}`
      y.textContent = "Please enter a valid message"
      y.style.display = "none"

      xy.appendChild(x)
      xy.appendChild(y)

      parentContainer.insertBefore(xy, parentContainer.firstChild)

      if (scroll === "doScroll") {
        let scrollElement = document
          .getElementById(`txt_area_${ele}`)
          .scrollIntoView({ behavior: "smooth", block: "center" })
      }
    } else {
      let x = document.getElementById(`txt_area_wrap_${ele}`)
      if (x) x.remove()
    }
  }

  return (
    <>
      <Script src={`https://js.hcaptcha.com/1/api.js?recaptchacompat=off`} />
      <div
        id="captcha-container"
        className="h-captcha"
        data-sitekey={process.env.H_CAPTCHA_KEY}
        data-size="invisible"
      ></div>
      {
        <form
          className={props.formClassName}
          id={props.id}
          onSubmit={evt => handleSubmit(evt)}
        >
          <Row className="justify-content-between">
            {data.map((item, idx) => {
              if (
                item.type === "text" ||
                item.type === "password" ||
                item.type === "number" ||
                item.type === "email"
              ) {
                return (
                  <>
                    <Col
                      lg={6}
                      className={`position-relative inp-field-wrapper ${
                        item.parentClassName ? item.parentClassName : ""
                      }`}
                    >
                      <input
                        type={item.type}
                        className={`${
                          props.inputClassName ? props.inputClassName : ""
                        } ${item.error ? props.inputErrorClassName : ""} ${
                          props.submissionError &&
                          props.submissionError.type === item.type
                            ? props.inputErrorClassName
                            : ""
                        } ${item.inputClassName ? item.inputClassName : ""} ${
                          item?.value?.length > 0 ? "valid" : ""
                        } ${paramXeroProd && "valid"}`}
                        placeholder={item.placeholder}
                        onChange={e => {
                          handleChange(e.target.value, idx, item.type)
                          multiFormData &&
                            setMultiFormData({
                              ...multiFormData,
                              [item.name]: e.target.value,
                            })
                        }}
                        value={item.value}
                        name={item.name}
                      />
                      <span className="floating-label p14 position-absolute">
                        <p>{item.label}</p>
                      </span>

                      {(item.error ||
                        (props.submissionError &&
                          props.submissionError.type === item.type)) && (
                        <div className={props.errorClassName}>
                          <p>{item.errorMessage}</p>
                        </div>
                      )}
                    </Col>
                  </>
                )
              }
              if (item.type === "checkbox") {
                return (
                  <>
                    <div className="d-flex flex-wrap mb30 align-items-center gap-8 mt16">
                      <p className="p18 font-roboto form-text m-0">
                        Awards category:
                      </p>
                      <p className="m-0 form-tooltip-text">
                        Atleast one category to be selected
                      </p>
                    </div>

                    <Col
                      lg={12}
                      className="d-flex flex-wrap gap-8 checkbox-wrapper"
                    >
                      {item.choices.map((ele, ind) => {
                        return (
                          <div className="position-relative d-inline-flex">
                            <input
                              type="checkbox"
                              id={ele}
                              name={ele}
                              value={ele}
                              onClick={() =>
                                checkboxClick(
                                  ele,
                                  item.placeholders[ind],
                                  "doScroll"
                                )
                              }
                              className="checkbox m-0"
                            />
                            <label for={ele} className="checkbox-label">
                              {ele}
                            </label>
                          </div>
                        )
                      })}
                    </Col>
                  </>
                )
              }
            })}
          </Row>

          {props.hiddenCountry && (
            <div className={`position-relative d-none`}>
              <input
                type="hidden"
                className={` ${
                  props.inputClassName ? props.inputClassName : ""
                } `}
                value={IpData.country}
                name="country"
              />
              <span className="floating-label p14 position-absolute">
                <p>Country</p>
              </span>
            </div>
          )}
          <div className="d-flex flex-wrap mb30 align-items-center gap-8">
            <p className="p18 font-roboto form-text m-0">Tell us more:</p>
            <p className="m-0 form-tooltip-text">
              Mandatory to fill all the fields
            </p>
          </div>

          <div
            id="awards_textarea"
            className="d-flex flex-wrap justify-content-between"
          ></div>
          {multiFormData ? (
            page === 0 ? (
              <div className="d-flex justify-content-end">
                <div className="btn-wrapper">
                  <Buttons
                    // onClick={evt => handleSubmit(evt)}
                    theme={props.buttonClassName}
                    disableButton={emptyFields}
                    onClick={() => setPage(page + 1)}
                    text={props.buttonText}
                    id={props.buttonId}
                  />
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-end"
                style={{ gap: "8px" }}
              >
                <div className="btn-wrapper">
                  <Buttons
                    // onClick={evt => handleSubmit(evt)}
                    theme={`${props.buttonClassName}`}
                    disableButton={loading}
                    onClick={() => setPage(page - 1)}
                    text={"Previous"}
                    id={props.buttonId}
                  />
                </div>
                <div className="btn-wrapper">
                  <Buttons
                    onClick={evt => handleSubmit(evt)}
                    theme={props.buttonClassName}
                    disableButton={loading}
                    text={
                      loading ? (
                        <div className="loading-anim">
                          LOADING
                          <span className="ellipsis-anim">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </span>
                        </div>
                      ) : (
                        props.buttonText
                      )
                    }
                    id={props.buttonId}
                  />
                </div>
              </div>
            )
          ) : (
            <Buttons
              // onClick={evt => handleSubmit(evt)}
              theme={props.buttonClassName}
              disableButton={loading}
              text={
                loading ? (
                  <div className="loading-anim">
                    LOADING
                    <span className="ellipsis-anim">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </span>
                  </div>
                ) : (
                  props.buttonText
                )
              }
              id={props.buttonId}
            />
          )}
          {props.children}
        </form>
      }
    </>
  )
}

export default AwardsFormBuilder

//https://api-msp.superops.ai/accounts-web/accounts/validateCaptcha
